import { getCalApi } from '@calcom/embed-react';
import { Button, toast } from '@montugroup/design-system';
import { Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

import settings from '@/data/constants';

interface PrefilledConfig {
  name?: string;
  email?: string;
  phoneNumber?: string;
}

export type CalendarPopupButtonProps = PropsWithChildren<{
  link: string;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  formData?: PrefilledConfig;
  disabled?: boolean;
  isButton?: boolean;
  disabledMessage?: {
    text: string;
    type: string;
  };
  onOpen?: () => void;
  onBookingSuccessful?: () => void;
  onRescheduleBookingSuccessful?: () => void;
}>;

export default function CalendarPopupButton({
  children,
  link,
  formData,
  disabled,
  disabledMessage,
  isButton,
  onOpen,
  onRescheduleBookingSuccessful,
  onBookingSuccessful,
  ...rest
}: CalendarPopupButtonProps) {
  useEffect(() => {
    (async function initCalendar() {
      const cal = await getCalApi(settings.calendar.embedJsUrl);
      cal('ui', { hideEventTypeDetails: false, layout: 'month_view' });

      if (typeof onOpen === 'function') {
        cal('on', { action: 'linkReady', callback: onOpen });
      }
      if (typeof onBookingSuccessful === 'function') {
        cal('on', { action: 'bookingSuccessful', callback: onBookingSuccessful });
      }
      if (typeof onRescheduleBookingSuccessful === 'function') {
        cal('on', { action: 'rescheduleBookingSuccessful', callback: onRescheduleBookingSuccessful });
      }
    })();
  }, [onOpen, onBookingSuccessful]);

  const handleOnClickDisabledButton = () => {
    if (disabled) {
      toast.warning({
        title: disabledMessage?.type,
        message: disabledMessage?.text
      });
    }
  };

  const StyledDisabledButtonContainer = styled(Box)({
    cursor: 'not-allowed',

    'button.Mui-disabled': {
      border: 'none'
    }
  });

  const commonProps = {
    'data-cal-config': JSON.stringify(formData ?? {}),
    'data-cal-link': link,
    'data-cal-origin': settings.calendar.calOrigin,
    'data-testid': 'calendar-popup-trigger'
  };

  const PopupButton = (
    <Button disabled={disabled} color={'secondary'} {...commonProps} {...rest}>
      {children}
    </Button>
  );

  const PopupLink = (
    <Link color="secondary" {...commonProps} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
      {children}
    </Link>
  );

  const PopupTrigger = isButton ? PopupButton : PopupLink;

  return disabled ? (
    <StyledDisabledButtonContainer onClick={handleOnClickDisabledButton}>{PopupButton}</StyledDisabledButtonContainer>
  ) : (
    PopupTrigger
  );
}
