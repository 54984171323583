// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useNoticeOfTreatmentPdf } from '@/hooks/document/useNoticeOfTreatmentPdf';
import { usePostProductSurvey } from '@/hooks/rest/usePostProductSurvey';
import { SectionBody } from '@/shared-ui/PageElements/SectionBody';
import { useMedicationRequiresSuitableDevice } from '@/state-management/hooks/useMedicationRequiresSuitableDevice';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress, Stack, Tooltip, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import React, { useState } from 'react';

import type { FeedbackPayload } from '@/components/PatientPageV2/components/PrescriptionPad';
import {
  PrescriptionPadStep,
  PrescriptionPadWrapper,
  transformAndMergeMedicationsAndItemsToReissue,
  transformFeedbackPayload,
  transformPrescriptionsPayload,
  transformV3DeviceProductsToV4DeviceProducts
} from '@/components/PatientPageV2/components/PrescriptionPad';
import {
  FF_CONSULTATION_COMPLETION_PROMPT,
  FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL,
  FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION,
  FF_WA_NOTIFICATION_OF_TREATMENT
} from '@/constants/featureFlags';
import { useAccessControl, useFeatureFlags } from '@/hooks';
import { useGetPrescribedProducts } from '@/hooks/prescribedProducts';
import { usePrescribePrescriptions } from '@/hooks/prescription/usePrescribePrescriptions';
import { usePostPrescription } from '@/hooks/rest/usePostPrescription';
import FullScreenLoader from '@/shared-ui/FullScreenLoader/FullScreenLoader';
import { useAppStore } from '@/state-management';
import type { PrescriptionAttributes, TypedResponse } from '@/types';
import { UserRole } from '@/types';

import { Logger } from '@montu-web/utilities';
import { ConfirmDialog, toast } from '@montugroup/design-system';
import { useModal } from 'mui-modal-provider';
import { listSorter } from '../../assets/js/util';
import { makeDelete, makeGET, makePUT } from '../../data/service/dataService';
import { StoreContext } from '../../data/store';
import MaterialTablePrescription from '../../pages/MaterialTablePrescription';
import { getCancelledOrDeletedStatus } from '../../utils/prescription';
import PaginationComponent from '../Pagination';
import CancelPrescribedMedicationModal from './components/CancelPrescribedMedicationModal/CancelPrescribedMedicationModal';
import NoticeOfTreatmentModal from './components/NoticeOfTreatmentModal/NoticeOfTreatmentModal';
import { NoticeOfTreatmentPDFViewer } from './components/NoticeOfTreatmentPDFViewer/NoticeOfTreatmentPDFViewer';
import { WaNotificationBanner } from './components/WaNotificationBanner/WaNotificationBanner';

const logger = new Logger('PMS:PrescriptionTab');

type PrescriptionsProps = {
  data: string | any[];
  patientId: string | number;
  refresh: () => void;
  patientActive: any;
  dischargeProgressStatus: any;
  classes: any;
  doctor: { id: number; prescriber_number: string; DoctorUser: any };
  patient: { hasValidConcessionCard: any };
  isLoading: any;
};

const Prescriptions = (props: PrescriptionsProps) => {
  const { showModal } = useModal();
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const isPatPrescribingSuitableDeviceModalEnabled = flags[FF_PAT_PRESCRIBING_SUITABLE_DEVICE_MODAL];
  const ffConfirmationCheckListEnabled = flags[FF_CONSULTATION_COMPLETION_PROMPT];
  const isWaNotificationOfTreatment = flags[FF_WA_NOTIFICATION_OF_TREATMENT];
  const prescriptionData = props.data ? listSorter(props.data, 'order_date') : [];
  const { postPrescription, isPending: isPostingPrescription } = usePostPrescription();
  const { refetch: refetchPrescribedProducts } = useGetPrescribedProducts({
    patientId: `${props?.patientId ?? ''}`
  });

  const [addScript, setAddScript] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tablePerPage] = React.useState(5);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isNoticeOfTreatmentModalOpen, setIsNoticeOfTreatmentModalOpen] = useState(false);
  const { pdfString, isLoading: isPdfLoading, error: pdfError, fetchPdf } = useNoticeOfTreatmentPdf();

  const [loading, setLoading] = useState(false);

  const {
    state: {
      authState: { user }
    }
  } = React.useContext(StoreContext);
  const { allowAccess, restrictAccess } = useAccessControl();

  const {
    prescriptionPad: {
      step: createScriptStep,
      devicePadItems: devicePadItems,
      medicationPadItems: medicationPadItems,
      actions: reviewActions
    },
    prescriptionHistory: { itemsToAction, itemsToCancel, itemsToReissue },
    waHealth: { isWaPatient }
  } = useAppStore.use.prescriptionAssistant();
  const updatePrescriptionPadStep = useAppStore.use.updatePrescriptionPadStep();
  const resetPrescriptionPad = useAppStore.use.resetPrescriptionPad();
  const resetPrescriptionHistory = useAppStore.use.resetPrescriptionHistory();
  const isValidPrescriptionPad = useAppStore.use.isValidPrescriptionPad()();
  const productSurveyFeedbackPayload = transformFeedbackPayload(medicationPadItems);
  const medicationProductsPayload = transformPrescriptionsPayload(medicationPadItems);
  const shouldDisplayForWaPatient = isWaNotificationOfTreatment && isWaPatient;

  const medicationsPrescribeEndpointPayload = transformAndMergeMedicationsAndItemsToReissue(
    medicationPadItems,
    itemsToReissue
  );
  const hasCompletedActions = !ffV4PrescriptionAssistantCancelPrescribedMedication ? true : itemsToAction.length === 0;
  const productSurveyMutation = usePostProductSurvey();

  const { mutateAsync: prescribePrescriptions } = usePrescribePrescriptions();

  const padIncludesMedsThatRequireSuitableDevice = useMedicationRequiresSuitableDevice();
  const [showSuitableDeviceModal, setShowSuitableDeviceModal] = useState(false);
  const [buttonHovered, setButtonHovered] = useState(false);
  const theme = useTheme();

  const handleDeleteRecord = async (prescriptionId: string) => {
    const resp = await makeDelete(`prescription?id=${prescriptionId}`, {}, 'delete-prescription');
    if (resp) {
      toast.success('Delete the Prescription Successfully');
      props.refresh();
    } else {
      toast.error('Cannot delete the Prescription');
    }
  };

  const deleteRecord = (prescriptionId: string) => {
    if (!props.patientActive || props.dischargeProgressStatus) {
      return;
    }

    const modal = showModal(ConfirmDialog, {
      title: 'Delete prescription',
      children: 'Are you sure want to delete this prescription?',
      actions: (
        <>
          <Button
            onClick={() => {
              modal.hide();
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            onClick={() => {
              modal.hide();
              handleDeleteRecord(prescriptionId);
            }}
          >
            Delete
          </Button>
        </>
      )
    });
  };
  // Show Delete only for Super-admins and Doctors
  const validateRole = () => {
    if ([1, 4, 6].includes(JSON.parse(user as string).role_id)) {
      return true;
    }
    return false;
  };

  const getUrlOfPrescription = (id: any, zeroRepeat: boolean, parent: number) => {
    const apiPath =
      zeroRepeat && parent === 2
        ? `prescription/getPathOfRefillPrescription/${id}`
        : `prescription/getPathOfPrescription/${id}`;
    makeGET(apiPath, 'prescriptions').then((res: { url: string } | null) => {
      if (res && res.url) {
        window.open(res.url);
      } else {
        toast.error('Prescription not found!');
      }
    });
  };

  const handleCancelPrescription = async (cancelId: number) => {
    // call the delete api
    const resp = await makePUT(`prescription/${cancelId}`, {}, 'cancel-prescription');
    if (resp && resp.data.success) {
      toast.success(resp.data.message);
      props.refresh();
    } else {
      toast.error(resp?.data.message || 'Cancel prescription failed');
    }
  };

  const cancelRecord = (cancelId: number, prescriptionID: string) => {
    const modal = showModal(ConfirmDialog, {
      title: 'Cancel eScript',
      children: <>Are you sure want to cancel eScript - {prescriptionID}?</>,
      actions: (
        <>
          <Button
            color="error"
            onClick={() => {
              modal.hide();
              handleCancelPrescription(cancelId);
            }}
          >
            Yes
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              modal.hide();
            }}
          >
            Cancel
          </Button>
        </>
      )
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setValue(event.target.value);
  };

  const DataFormatter = (data: { Product: { name: string }; other_product: any; quantity: any }, type: string) => {
    if (type === 'productName') {
      return (data.Product && data.Product.name) || data.other_product;
    } else if (type === 'quantity') {
      return <ul style={{ listStyle: 'none' }}>{data.quantity}</ul>;
    } else if (type === 'repeats') {
      return <ul style={{ listStyle: 'none' }}>{data}</ul>;
    }
  };

  const rowHeader = [];
  let id = 1;
  for (const element of prescriptionData) {
    let count = 0;
    const prescribedProductsRows = [];

    for (const prescribedProducts of element.PrescribedProducts) {
      const refillPrescriptionRow = [];
      const prescribedProductsData = {
        id: ++id,
        prescriptionId: prescribedProducts.prescription_product_code || element.order_code,
        prescriptionIdSearch: prescribedProducts.prescription_product_code || element.order_code,
        date: element.order_date,
        medication: DataFormatter(prescribedProducts, 'productName'),
        medicationSearch: DataFormatter(prescribedProducts, 'productName'),
        quantity: DataFormatter(prescribedProducts, 'quantity'),
        script: element.url,
        script_id: element.id,
        cancel_id: prescribedProducts.id,
        delete_product_id: (prescribedProducts.Product && prescribedProducts.Product.formulation_id) || '',
        group_id: prescribedProducts.prescription_product_code || element.order_code,
        Status: prescribedProducts.active
          ? element.PrescriptionStatus.name
          : getCancelledOrDeletedStatus(element.order_code),
        parent: 1,
        child_prescriptions: [] as any[],
        repeat: 0
      };
      const childPrescription = [];
      const refillsPrescriptionsIdsArr: any[] = [];
      for (let k = 0; k < prescribedProducts.RefillPrescribedProducts.length; k++) {
        const refillPrescription = prescribedProducts.RefillPrescribedProducts[k];
        if (!refillsPrescriptionsIdsArr.includes(refillPrescription.refill_prescription_id)) {
          refillsPrescriptionsIdsArr.push(refillPrescription.refill_prescription_id);
        }
        const refillPrescriptionData = {
          id: ++id,
          prescriptionId: refillPrescription.refill_prescription_product_code || element.order_code,
          prescriptionIdSearch: refillPrescription.refill_prescription_product_code || element.order_code,
          parentPrescriptionId: element.order_code,
          date: refillPrescription.created_date,
          medication: refillPrescription.refill_prescription_product_code || element.order_code,
          medicationSearch: DataFormatter(prescribedProducts, 'productName'),
          quantity: refillPrescription.quantity,
          repeat: 0,
          script: refillPrescription.RefillPrescription
            ? refillPrescription.RefillPrescription.refill_prescription_url
            : null,
          script_id: refillPrescription.id,
          group_id: prescribedProducts.prescription_product_code || element.order_code,
          Status: !refillPrescription.active ? 'Cancelled' : element.PrescriptionStatus.name,
          parent: 2,
          base_id: prescribedProducts.prescription_product_code || element.order_code,
          isFinal: k === prescribedProducts.RefillPrescribedProducts.length - 1 ? true : false
        };
        refillPrescriptionRow.push(refillPrescriptionData);
        childPrescription.push(refillPrescription.refill_prescription_product_code || element.order_code);
        id++;
      }
      count++;
      prescribedProductsData.child_prescriptions = childPrescription;
      prescribedProductsData.repeat = DataFormatter(prescribedProducts.repeats, 'repeats');
      prescribedProductsRows.push(prescribedProductsData);
      prescribedProductsRows.push(...refillPrescriptionRow);
      id++;
    }

    const headerData = {
      order_code: element.order_code + ' (' + count + ')',
      script: element.id,
      Status: element.PrescriptionStatus.name,
      Date: moment(element.order_date).format('DD-MMM-YYYY'),
      delete_id: element.id,
      refillRows: {}
    };
    headerData.refillRows = prescribedProductsRows;
    rowHeader.push(headerData);
  }

  const checkForData = (data: string | any[]) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].toLowerCase().indexOf(value.toLowerCase()) > -1) {
        return true;
      }
    }
    return false;
  };

  const handleDeletePrescriptionBtn = () => {
    const deletePrescriptionObj = {
      title: '',
      field: 'cancel_id',
      grouping: false,
      sorting: false,
      filtering: false,
      cellStyle: { minWidth: 20, maxWidth: 20, cursor: 'pointer' },
      align: 'center',
      render: (rowData: { Status: string; parent: number; prescriptionId: string; cancel_id: number }) =>
        rowData.Status === 'Active' && props.patientActive && !props.dischargeProgressStatus ? (
          rowData.parent === 2 || (rowData.prescriptionId && rowData.prescriptionId.substr(0, 4) === 'ALPR') ? (
            <DeleteIcon fontSize="small" style={{ color: 'transparent' }} />
          ) : (
            <Link onClick={() => cancelRecord(rowData.cancel_id, rowData.prescriptionId)}>
              <DeleteIcon fontSize="small" style={{ color: 'black' }} />
            </Link>
          )
        ) : null
    };
    return restrictAccess([UserRole.Admin], deletePrescriptionObj);
  };

  let refillColumn = [
    {
      title: 'id',
      field: 'id',
      hidden: true,
      sorting: false,
      grouping: false,
      filtering: false,
      cellStyle: { width: '1%' }
    },
    {
      title: '',
      field: 'prescriptionId',
      sorting: true,
      grouping: false,
      filtering: true,
      hidden: true,
      cellStyle: { width: '20%' }
    },
    {
      title: 'Product',
      sort: false,
      field: 'medication',
      filtering: true,
      display: true,
      cellStyle: (cellValue: string) => {
        const fontWeight = cellValue?.startsWith('ALEP') || cellValue?.startsWith('ALPR') ? '' : 'Bold';
        return { fontWeight, minWidth: 220, maxWidth: 220 };
      }
    },
    {
      title: 'Quantity',
      field: 'quantity',
      grouping: false,
      sorting: false,
      filtering: false,
      align: 'center',
      cellStyle: { minWidth: 50, maxWidth: 50 }
    },
    {
      title: 'Repeats',
      field: 'repeat',
      grouping: false,
      sorting: false,
      filtering: false,
      align: 'center',
      cellStyle: { paddingLeft: 'none', minWidth: 30, maxWidth: 30 }
    },
    {
      title: 'Status',
      field: 'Status',
      grouping: false,
      sorting: false,
      filtering: false,
      cellStyle: { width: '8%', minWidth: 50, maxWidth: 50 }
    },
    {
      title: 'Date',
      field: 'date',
      grouping: false,
      sorting: true,
      filtering: false,
      align: 'center',
      cellStyle: { paddingLeft: 'none', minWidth: 100, maxWidth: 100, textAlign: 'center' },
      render: (rowdata: { date: moment.MomentInput }) => moment(rowdata.date).format('DD-MMM-YYYY')
    },
    {
      title: '',
      field: 'script',
      grouping: false,
      sorting: false,
      filtering: false,
      cellStyle: { minWidth: 20, maxWidth: 20, cursor: 'pointer' },
      align: 'center',
      render: (rowData: { script: any; script_id: any; parent: number }) =>
        rowData.script ? (
          <Link onClick={() => getUrlOfPrescription(rowData.script_id, true, rowData.parent)}>
            <VisibilityIcon fontSize="small" style={{ color: 'black' }} />
          </Link>
        ) : (
          'Creating...'
        )
    }
  ];

  // check the user access control on the delete button
  const deleteBtn = handleDeletePrescriptionBtn();

  if (deleteBtn) {
    // @ts-expect-error: TS2322: typing needs to be fix
    refillColumn = [...refillColumn, deleteBtn];
  }

  const paperRefillColumn = refillColumn.map((a) => ({ ...a }));

  // @ts-expect-error: TS2339: typing needs to be fix
  delete paperRefillColumn[2].defaultGroupOrder;
  paperRefillColumn[1].hidden = true;

  const indexOfLastTable = currentPage * tablePerPage;
  const indexOfFirstTable = indexOfLastTable - tablePerPage;
  const currentTable = rowHeader.slice(indexOfFirstTable, indexOfLastTable);
  let isEmpty = true;

  const paginate = (e: React.SyntheticEvent, pageNumber: number) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  const handleCreateNewScript = () => {
    updatePrescriptionPadStep(PrescriptionPadStep.Create);
    setAddScript(true);
  };

  const handleCreateNewScriptBtn = () => {
    const createNewScriptBtn = (
      <Button
        variant="contained"
        color="secondary"
        className={props.classes.button}
        disabled={!props.patientActive || props.dischargeProgressStatus}
        onClick={handleCreateNewScript}
        style={{ marginLeft: '0.5rem' }}
        aria-label="create new script"
      >
        Create New Script
      </Button>
    );
    return allowAccess([UserRole.Doctor], createNewScriptBtn);
  };

  /**
   * Generates a prescription payload by checking for valid devices and medications from the
   * `PrescriptionContext`
   */
  const generatePrescriptionProductsPayload = () => {
    /**
     * @type {import('@/hooks/prescription/usePrescriptionForm').DeviceProduct[]}
     */
    const validDevices = devicePadItems.filter((device) => !!device);
    if (validDevices.length > 0) {
      return [...medicationProductsPayload, ...validDevices];
    }

    return medicationProductsPayload;
  };

  const waitingToCancelItems = itemsToCancel.length > 0;
  const noItemsToAction = itemsToAction.length === 0;

  const createPayload = (patientId: string | number, doctorId: number) => ({
    data: {
      order_date: new Date(),
      patient_id: Number(patientId),
      doctor_id: doctorId
    },
    products: generatePrescriptionProductsPayload()
  });

  const handleResponse = async (response: TypedResponse<PrescriptionAttributes>) => {
    if (!response || response.status >= 300) {
      throw new Error(`Unexpected response status: ${response?.status}`);
    }

    const prescriptionCode = ffV4PrescriptionAssistantCancelPrescribedMedication
      ? // @ts-expect-error: TS2339: type needs to be fixed
        response.body.prescription.orderCode
      : (await response.json()).order_code;

    toast.success(`Prescription - ${prescriptionCode} has been processed and ePrescription is also created!`);
    props.refresh();
  };

  const submitProductSurveyFeedback = async (feedbackPayload: FeedbackPayload[], doctorId: any, patientId: any) => {
    if (feedbackPayload?.length) {
      productSurveyMutation?.mutate({
        doctorId,
        patientId,
        feedback: feedbackPayload
      });
    }
  };

  const showErrorNotification = () => {
    toast.error({
      title: 'Prescription Not Created',
      message: 'Error happened while eprescription creation, Please try again!'
    });
  };

  const finaliseProcess = () => {
    setLoading(false);
    resetPrescriptionPad();
    resetPrescriptionHistory();
    updatePrescriptionPadStep(PrescriptionPadStep.Create);
    refetchPrescribedProducts();
    setAddScript(false);
  };

  const canOpenCancelModal = () => noItemsToAction && waitingToCancelItems && !isCancelModalOpen;

  const onReviewScript = () => {
    canOpenCancelModal() ? setIsCancelModalOpen(true) : handleCreateScript();
  };

  const handleSubmitScript = async () => {
    setLoading(true);

    try {
      const response = await createPrescriptionAPI();
      // @ts-expect-error: TS2345: type needs to be fixed
      await handleResponse(response);
      submitProductSurveyFeedback(productSurveyFeedbackPayload, props.doctor.id, props.patientId);
    } catch {
      showErrorNotification();
    } finally {
      finaliseProcess();
    }
  };

  const handleCreateScript = () => {
    if (createScriptStep === PrescriptionPadStep.Create) {
      updatePrescriptionPadStep(PrescriptionPadStep.Confirm);
      return;
    }

    if (shouldDisplayForWaPatient) {
      try {
        fetchPdf({
          patientId: props.patientId as string,
          medicationProductsPayload
        });
        setIsNoticeOfTreatmentModalOpen(true);
      } catch (error) {
        // @TODO: Unhappy path flow https://montugroup.atlassian.net/browse/B2C-5074
        logger.error('Error fetching PDF:', error);
      }
      return;
    }

    handleSubmitScript();
  };

  const createPrescriptionAPI = async () => {
    const validDevices = devicePadItems.filter((device) => !!device);
    if (ffV4PrescriptionAssistantCancelPrescribedMedication) {
      const payload = {
        orderDate: new Date(),
        products: [...medicationsPrescribeEndpointPayload],
        devices: transformV3DeviceProductsToV4DeviceProducts(validDevices)
      };

      return prescribePrescriptions({
        params: { patientId: props.patientId as string },
        // @ts-expect-error: TS2339: type needs to be fixed
        body: payload
      });
    }
    const payload = createPayload(props.patientId, props.doctor.id);
    // @ts-expect-error: TS2339: type needs to be fixed
    return postPrescription(payload);
  };

  // @TODO:[B2C-4637] This submitScript function will need to be deleted on V4 release and we will be using
  // the ⛔️ handleSubmitScript ⛔️ function instead
  const submitScript = async () => {
    setLoading(true);

    try {
      const response = await createPrescriptionAPI();

      if (!response || response.status >= 300) {
        throw new Error(`Unexpected response status: ${response?.status}`);
      }

      const prescriptionCode = ffV4PrescriptionAssistantCancelPrescribedMedication
        ? // @ts-expect-error: TS2339: type needs to be fixed
          response.body.prescription.id
        : // @ts-expect-error: TS2339: type needs to be fixed
          (await response.json()).order_code;

      toast.success(`Prescription - ${prescriptionCode} has been processed and ePrescription is also created!`);

      props.refresh();

      // Submit product survey feedback
      if (productSurveyFeedbackPayload?.length) {
        productSurveyMutation?.mutate({
          doctorId: props.doctor.id,
          patientId: props.patientId as number,
          feedback: productSurveyFeedbackPayload
        });
      }
    } catch {
      toast.error('Error happened while eprescription creation, Please try again!');
    } finally {
      setLoading(false);
      resetPrescriptionPad();
      resetPrescriptionHistory();
      updatePrescriptionPadStep(PrescriptionPadStep.Create);
      // refetch prescribed products on script creation
      refetchPrescribedProducts();
      setAddScript(false);
    }
  };

  // @TODO:[B2C-4637] This onCreateScript function will need to be deleted on V4 release and we will be using
  // the ⛔️ onReviewScript ⛔️ function instead
  const onCreateScript = () => {
    // Preview script details before the user can submit
    if (createScriptStep === PrescriptionPadStep.Create) {
      return updatePrescriptionPadStep(PrescriptionPadStep.Confirm);
    }

    if (shouldDisplayForWaPatient) {
      try {
        fetchPdf({
          patientId: props.patientId as string,
          medicationProductsPayload
        });
        setIsNoticeOfTreatmentModalOpen(true);
      } catch (error) {
        // @TODO: Unhappy path flow https://montugroup.atlassian.net/browse/B2C-5074
        logger.error('Error fetching PDF:', error);
      }
      return;
    }

    submitScript();
  };

  const onScriptSubmit = () => {
    const canShowSuitableDeviceModal =
      isPatPrescribingSuitableDeviceModalEnabled &&
      padIncludesMedsThatRequireSuitableDevice &&
      createScriptStep === PrescriptionPadStep.Create;

    if (canShowSuitableDeviceModal) {
      setShowSuitableDeviceModal(true);
    } else {
      ffV4PrescriptionAssistantCancelPrescribedMedication ? onReviewScript() : onCreateScript();
    }
  };

  const handleCancelScript = () => {
    resetPrescriptionPad();
    resetPrescriptionHistory();
    updatePrescriptionPadStep(PrescriptionPadStep.Create);
    setAddScript(false);
  };

  const handleConfirmCancelPrescription = () => {
    setIsCancelModalOpen(false);

    if (!ffV4PrescriptionAssistantCancelPrescribedMedication) {
      return;
    }

    const hasNoValidMedication = !medicationPadItems[0]?.medicationPadItem?.productName;

    if (hasNoValidMedication) {
      // Doctor is only cancelling medications
      resetPrescriptionPad();
      resetPrescriptionHistory();
      updatePrescriptionPadStep(PrescriptionPadStep.Create);
      refetchPrescribedProducts();
      props.refresh();
      setAddScript(false);
    } else {
      // Doctor is both cancelling and prescribing medications
      updatePrescriptionPadStep(PrescriptionPadStep.Confirm);
    }
  };

  const disableCreateScriptButton = loading || !isValidPrescriptionPad;

  const renderCancelAndCreateScriptButtons = () => {
    if (!hasCompletedActions) {
      return null;
    }

    if (ffConfirmationCheckListEnabled) {
      const disabledDueToUnactionedConfirmCheckList =
        createScriptStep === PrescriptionPadStep.Confirm && reviewActions.some((action) => !action.checked);

      return (
        <Stack flexDirection="row-reverse" mt={14}>
          <Tooltip
            open={disabledDueToUnactionedConfirmCheckList && buttonHovered}
            title="Please complete the required actions checklist"
            placement="top-start"
            disableFocusListener
            disableTouchListener
            arrow
          >
            <Box
              onMouseEnter={() =>
                disabledDueToUnactionedConfirmCheckList &&
                createScriptStep === PrescriptionPadStep.Confirm &&
                setButtonHovered(true)
              }
              onMouseLeave={() => setButtonHovered(false)}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={onScriptSubmit}
                disabled={disableCreateScriptButton || disabledDueToUnactionedConfirmCheckList}
                startIcon={loading ? <CircularProgress size={16} /> : null}
                sx={{
                  marginLeft: '4px',
                  borderRadius: 1,
                  textTransform: 'none',
                  letterSpacing: 1,
                  fontWeight: '600',
                  boxShadow:
                    '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
                }}
              >
                {`${createScriptStep === PrescriptionPadStep.Create ? 'Review' : 'Create'} Script`}
              </Button>
            </Box>
          </Tooltip>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelScript}
            disabled={loading}
            sx={{ borderRadius: 1, textTransform: 'none', letterSpacing: 1, fontWeight: 'bold' }}
          >
            Cancel
          </Button>
        </Stack>
      );
    }
    return (
      <>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCancelScript}
          disabled={loading}
          sx={{ borderRadius: 1, textTransform: 'none', letterSpacing: 1, fontWeight: 'bold' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onScriptSubmit}
          disabled={disableCreateScriptButton}
          startIcon={loading ? <CircularProgress size={16} /> : null}
          sx={{
            borderRadius: 1,
            textTransform: 'none',
            letterSpacing: 1,
            fontWeight: '600',
            boxShadow:
              '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)'
          }}
        >
          {`${createScriptStep === PrescriptionPadStep.Create ? 'Review' : 'Create'} Script`}
        </Button>
      </>
    );
  };

  const renderCreateScript = () => (
    <>
      <PrescriptionPadWrapper
        doctorId={props.doctor.id}
        patientId={props.patientId as number}
        prescriberNumber={props.doctor?.prescriber_number}
        doctorPatientDetails={{
          doctor: props.doctor.DoctorUser,
          // @ts-expect-error: TS2740: type needs to be fixed
          patient: props.patient
        }}
        isConcessionCardHolder={props.patient.hasValidConcessionCard}
        showDeviceSuitabilityModal={showSuitableDeviceModal}
        deviceSuitabilityModalOnConfirm={() => {
          setShowSuitableDeviceModal(false);
          ffV4PrescriptionAssistantCancelPrescribedMedication ? onReviewScript() : onCreateScript();
        }}
        deviceSuitabilityModalOnCancel={() => setShowSuitableDeviceModal(false)}
      />
      <FullScreenLoader open={isPostingPrescription} onClose={() => {}} loadingMessage="Submitting prescription..." />
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4} my={10}>
        {ffV4PrescriptionAssistantCancelPrescribedMedication && (
          <CancelPrescribedMedicationModal
            patientId={props.patientId as string}
            isOpen={isCancelModalOpen}
            onClose={() => setIsCancelModalOpen(false)}
            onConfirm={() => handleConfirmCancelPrescription()}
          />
        )}
        {renderCancelAndCreateScriptButtons()}
      </Stack>
      <NoticeOfTreatmentModal
        isOpen={isNoticeOfTreatmentModalOpen}
        onClose={() => setIsNoticeOfTreatmentModalOpen(false)}
        onConfirm={() => {
          setIsNoticeOfTreatmentModalOpen(false);
          ffV4PrescriptionAssistantCancelPrescribedMedication ? handleSubmitScript() : submitScript();
        }}
      >
        <NoticeOfTreatmentPDFViewer isPdfLoading={isPdfLoading} pdfError={pdfError} pdfString={pdfString} />
      </NoticeOfTreatmentModal>
    </>
  );

  return (
    <Box>
      {addScript && <WaNotificationBanner />}
      <Box component="p" mt={1} className={props.classes.heading} fontSize={14}>
        Prescriptions
      </Box>
      <SectionBody paddingX={0} paddingTop={4}>
        {addScript ? (
          renderCreateScript()
        ) : (
          <div style={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between">
              <Box mt={1} height="0.5">
                {currentTable.length > 0 && (
                  <div>
                    <TextField
                      id="outlined-multiline-flexible"
                      variant="outlined"
                      placeholder="Search"
                      maxRows={4}
                      value={value}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </Box>

              <Box component="div" display="flex" justifyContent="end" height="0.5" paddingTop="0.5rem">
                <Button
                  variant="contained"
                  color="secondary"
                  mr={2}
                  className={props.classes.button}
                  disabled={!props.patientActive || props.dischargeProgressStatus}
                  onClick={props.refresh}
                >
                  <i className="fa fa-refresh"></i>
                </Button>
                {handleCreateNewScriptBtn()}
              </Box>
            </Box>
            {value && value.length > 0 ? (
              <>
                {rowHeader.map(function (headerData, index) {
                  // @ts-expect-error: TS2339: type needs to be fixed
                  const data = headerData.refillRows.filter(function (x) {
                    if (
                      (x.prescriptionId && x.prescriptionId.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
                      (x.medicationSearch && x.medicationSearch.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
                      (x.parentPrescriptionId &&
                        x.parentPrescriptionId.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
                      (x.child_prescription && checkForData(x.child_prescription))
                    ) {
                      isEmpty = false;
                      return x;
                    }
                  });
                  if (data && data?.length > 0) {
                    return (
                      <div
                        key={index}
                        className={
                          headerData.order_code.startsWith('ALE') ? 'border-group-e-script' : 'border-group-paper'
                        }
                      >
                        <MaterialTablePrescription
                          refillColumn={headerData.order_code.startsWith('ALE') ? refillColumn : paperRefillColumn}
                          refillRows={data}
                          rowHeader={headerData}
                          getUrlOfPrescription={getUrlOfPrescription}
                          deleteRecord={deleteRecord}
                          validateRole={validateRole}
                          patientActive={props.patientActive}
                          dischargeProgressStatus={props.dischargeProgressStatus}
                        />
                      </div>
                    );
                  }
                })}
                {isEmpty && (
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100px'
                    }}
                  >
                    <br />
                    <p style={{ fontStyle: 'italic', fontSize: '13', color: '#9A9A9A' }}>No Results found</p>
                    <br />
                    <p>Try refining your search</p>
                  </Box>
                )}
              </>
            ) : (
              <>
                {props.isLoading ? (
                  <div style={{ textAlign: 'center', margin: '10%' }}>Loading...</div>
                ) : props.data?.length > 0 ? (
                  currentTable.map((headerData, index) => (
                    <div
                      key={index}
                      className={
                        headerData.order_code.startsWith('ALE') ? 'border-group-e-script' : 'border-group-paper'
                      }
                    >
                      <MaterialTablePrescription
                        refillColumn={headerData.order_code.startsWith('ALE') ? refillColumn : paperRefillColumn}
                        refillRows={headerData.refillRows}
                        rowHeader={headerData}
                        getUrlOfPrescription={getUrlOfPrescription}
                        deleteRecord={deleteRecord}
                        validateRole={validateRole}
                        patientActive={props.patientActive}
                        dischargeProgressStatus={props.dischargeProgressStatus}
                      >
                        {shouldDisplayForWaPatient && index === 0 && (
                          <Box marginTop={theme.spacing(2)} paddingLeft={theme.spacing(9)}>
                            <Link
                              href={`/patients/${props.patientId}?show=4`}
                              variant="subtitle2"
                              title="View patient documentation"
                            >
                              Click here to view the WA Treatment Notice
                            </Link>
                          </Box>
                        )}
                      </MaterialTablePrescription>
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: 'center', marginTop: '5%' }}>No matching records found</div>
                )}
                <PaginationComponent
                  tablePerPage={tablePerPage}
                  totalCount={rowHeader.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </>
            )}
          </div>
        )}
      </SectionBody>
    </Box>
  );
};
export default Prescriptions;
