import DisplayField from '@/components/fields/DisplayField';
import InputField from '@/components/fields/InputField';
import { FF_IMMUTABLE_CLINIC_NOTES } from '@/constants/featureFlags';
import { getUser } from '@/data/service/authService';
import { makePOST } from '@/data/service/dataService';
import { useAccessControl, useFeatureFlags } from '@/hooks';
import { SectionHeader } from '@/shared-ui/PageElements/SectionHeader';
import { UserRole } from '@/types';
import { Button, ConfirmDialog, toast } from '@montugroup/design-system';
import { Close } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useModal } from 'mui-modal-provider';
import React, { useState } from 'react';

export type PatientNoteState = {
  id: number | null;
  heading: string;
  notes: string;
  created_by: string;
  redFlag: boolean;
  redFlagComment: string;
};

export type ViewPatientNoteProps = {
  patientId: number;
  patientActive: boolean;
  selectedPatientNote: PatientNoteState;
  setSelectedPatientNote: React.Dispatch<React.SetStateAction<PatientNoteState | null>>;
  refetch: () => void;
};

/**
 *
 * Minimal refactor. Original component handles both create/edit functionality.
 *
 * v2.  Break out into create vs view components and flow
 */
const ViewPatientNote = ({
  patientId,
  selectedPatientNote,
  setSelectedPatientNote,
  patientActive,
  refetch
}: ViewPatientNoteProps) => {
  const { showModal } = useModal();
  const user = getUser();
  const { flags } = useFeatureFlags();
  const ffImmutableNotesActive = flags[FF_IMMUTABLE_CLINIC_NOTES];
  const { restrictAccess } = useAccessControl();

  const [patientNote, setPatientNote] = useState(selectedPatientNote);
  const [noteLoading, setNoteLoading] = useState(false);
  const newNote = !patientNote.id;

  const confirmImmutableSubmit = () => {
    const modal = showModal(ConfirmDialog, {
      id: 'noteSaveConfirmDialog',
      title: 'Create Note Confirmation',
      children: (
        <>
          <Typography variant="body1" fontWeight={500}>
            Are you ready to create a note?
          </Typography>
          <Typography variant="body1">
            Please note that notes cannot be edited after submission. Ensure all information is correct before
            proceeding
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            data-testid="patientNoteSaveCancel"
            variant="text"
            color="secondary"
            onClick={() => {
              modal.hide();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="patientNoteSaveConfirm"
            variant="contained"
            color="secondary"
            onClick={() => {
              modal.hide();
              saveNote();
            }}
          >
            Submit
          </Button>
        </>
      )
    });
  };

  const handleNoteSubmit = () => {
    if (!patientActive) {
      return;
    }
    if (patientNote.heading === '') {
      toast.warning({ title: 'Not Created!', message: 'Please have a heading.' });
      return;
    }

    if (ffImmutableNotesActive && patientNote.id) {
      return; // Prevent edit when FF_IMMUTABLE_CLINIC_NOTES is ON
    }

    if (ffImmutableNotesActive && newNote) {
      confirmImmutableSubmit(); // prompt create confirmation when FF_IMMUTABLE_CLINIC_NOTES is ON
    } else {
      saveNote();
    }
  };

  const saveNote = async () => {
    setNoteLoading(true);
    const sendBody = {
      redFlag: patientNote.redFlag,
      redFlagComment: patientNote.redFlagComment,
      notes: patientNote.notes,
      id: patientNote.id,
      heading: patientNote.heading,
      patient_id: patientId
    };
    // @ts-expect-error until we can move this from makePOST to fetch
    const response = await makePOST('patientNote', sendBody, 'createPatientNote-ConsultationTab');
    if (response) {
      refetch();
      toast.success('Note Created');
      setSelectedPatientNote(null);
    } else {
      toast.error('Failed to Create! Please try again later.');
    }
    setNoteLoading(false);
  };

  return (
    <Box>
      <Box display="flex" flexDirection="row-reverse">
        <Close
          fontSize="small"
          onClick={() => {
            if (!noteLoading) {
              setSelectedPatientNote(null);
            }
          }}
          color="error"
        />
      </Box>
      <SectionHeader headerText="Note Details" />
      <Stack spacing={2} mt={4}>
        <DisplayField
          id="patientNotePractitioner"
          label="Created By"
          fieldText={patientNote.id ? patientNote.created_by : `${user.first_name} ${user.last_name}`}
          labelPadding="0px"
          fieldPadding="0px"
          labelFontWeight={500}
          customGrid={[3, 9]}
        />
        <InputField
          id="patientNoteHeading"
          label="Note Header"
          type="text"
          placeholder="-"
          labelPadding="5px 0px 0px 0px"
          margin={0}
          customGrid={[3, 9]}
          disabled={!patientActive || (ffImmutableNotesActive && !newNote)}
          value={patientNote.heading}
          onChange={(e: { target: { value: string } }) =>
            setPatientNote((prev) => ({ ...prev, heading: e.target.value }))
          }
        />
        <InputField
          id="patientNote"
          label="Notes"
          type="text"
          placeholder="-"
          customGrid={[12, 12]}
          disabled={!patientActive || (ffImmutableNotesActive && !newNote)}
          display="block"
          multiline
          labelPadding="5px 0px 2px 0px"
          margin={0}
          InputClasses="textarea-h-150-px"
          value={patientNote.notes}
          onChange={(e: { target: { value: string } }) =>
            setPatientNote((prev) => ({ ...prev, notes: e.target.value }))
          }
        />
      </Stack>
      <Box width="90%" display="flex" m={1} height={40}>
        {restrictAccess(
          [UserRole.Admin],
          <Box width="33.3%">
            <FormControlLabel
              id="patientRedFlag"
              control={
                <Checkbox
                  checked={patientNote.redFlag}
                  disabled={!patientActive || (ffImmutableNotesActive && !newNote)}
                  onChange={() => setPatientNote((prev) => ({ ...prev, redFlag: !patientNote.redFlag }))}
                  color="default"
                />
              }
              label="Add Red Flag"
              value=""
            />
          </Box>
        )}

        {patientNote.redFlag && (
          <Box width="50%">
            <InputField
              id="patientNoteRedFlagComment"
              type="text"
              labelPadding="5px"
              disabled={!patientActive || (ffImmutableNotesActive && !newNote)}
              value={patientNote.redFlagComment}
              onChange={(e: { target: { value: string } }) =>
                setPatientNote((prev) => ({ ...prev, redFlagComment: e.target.value }))
              }
              margin={1}
              customGrid={[false, 8]}
            />
          </Box>
        )}
      </Box>
      <Stack mt={2} direction="row" spacing={4} justifyContent="flex-end">
        <Button
          id="closeNote"
          variant="outlined"
          color="secondary"
          onClick={() => {
            if (!noteLoading) {
              setSelectedPatientNote(null);
            }
          }}
        >
          {ffImmutableNotesActive && !newNote ? 'Close' : 'Cancel'}
        </Button>
        {(!ffImmutableNotesActive || (ffImmutableNotesActive && newNote)) && (
          <Button
            data-testid="patientNoteSave"
            variant="contained"
            color="secondary"
            onClick={handleNoteSubmit}
            disabled={noteLoading || !patientActive}
          >
            <Box width="100px">
              {noteLoading ? (
                <CircularProgress size={20} thickness={4} />
              ) : patientNote.id ? (
                'Save Note'
              ) : (
                'Create Note'
              )}
            </Box>
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default ViewPatientNote;
