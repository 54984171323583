import { EditModeContext, withEditModeContextProvider } from '@/components/Patient/EditModeContextProvider';
import { ATSI_LIST_OPTIONS } from '@/components/Patient/Patient.constants';
import { schema } from '@/components/Patient/forms/PatientInformation/PatientInformation.schema';
import { getAuthData } from '@/data/service/authService';
import { useGetDoctors } from '@/hooks/rest/useGetDoctors';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from '@montugroup/design-system';
import { Checkbox, Grid, Stack, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { AutocompleteElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useParams } from 'react-router-dom';
import type { z } from 'zod';
import EditableSections from '../../EditableSections';
import { usePostResendPatientConsent } from '../../hooks/usePostResentPatientConsent';

export type PatientInformationFormFields = z.infer<typeof schema>;

export interface PatientInformationProps {
  defaultValues: PatientInformationFormFields;
  showConsentFormResendLink: boolean;
  editable: boolean;
  onSubmit: (data: PatientInformationFormFields) => void;
  onResendConsent: (contents: { showConsentFormResendLink: boolean }) => void;
}

const StyledResendConsentButton = styled(Button)({
  textTransform: 'capitalize',
  fontSize: '1rem'
});

const StyledDatePicker = styled(DatePicker)({
  width: '100%'
});

const PatientInformation = (props: PatientInformationProps) => {
  const { defaultValues, editable, showConsentFormResendLink, onSubmit, onResendConsent } = props;

  const { isAdmin, isNurse, isManager } = getAuthData();
  const { isEditMode } = useContext(EditModeContext);
  const { data: listOfDoctors } = useGetDoctors();
  const { id: patientId } = useParams();
  const { resendConsentForm } = usePostResendPatientConsent(patientId);

  const listOfDoctorsForSelect =
    listOfDoctors?.filter(Boolean)?.map((doctor) => {
      return { id: doctor.doctor_id, label: doctor.doctor_name };
    }) ?? [];

  const listOfAtsiOptionsForSelect = ATSI_LIST_OPTIONS?.map((label, id) => {
    return { id, label };
  });

  const formContext = useForm<PatientInformationFormFields>({
    values: {
      ...defaultValues,
      // Transform default values from saved data to the format they are needed for the form fields
      DoB: defaultValues.DoB ? DateTime.fromISO(defaultValues.DoB) : null,
      atsi_status: defaultValues.atsi_status ? ATSI_LIST_OPTIONS.indexOf(defaultValues?.atsi_status as string) : null
    },
    resolver: zodResolver(schema)
  });

  const handleResendConsentForm = async () => {
    resendConsentForm(undefined, {
      onSuccess: () => {
        toast.success('Consent Form Link is sent via Email');
        onResendConsent({ showConsentFormResendLink: false });
      },
      onError: () => {
        toast.error('Failed to resend Consent Form. Please try again.');
      }
    });
  };

  const submitForm = (formData: FieldValues | undefined) => {
    const patientInformation = {
      ...formData,
      DoB: formData?.DoB?.toUTC().toISO() ?? null,
      pmsDoctor: formData?.pmsDoctor ?? null,
      atsi_status: ATSI_LIST_OPTIONS[formData?.atsi_status] ?? null
    };

    if (onSubmit) {
      onSubmit(patientInformation);
    }
  };

  return (
    <EditableSections
      title="Patient Information"
      editable={editable}
      formContext={formContext}
      actions={{ onSubmit: submitForm }}
    >
      <FormContainer formContext={formContext}>
        <Grid container columnSpacing={10} rowSpacing={5} marginY={2} data-testid="patient-information-form">
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="FirstName" label="First Name" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="LastName" label="Last Name" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="DoB"
              control={formContext.control}
              render={({ field: { onChange, value } }) => {
                /*
                  TODO - I've wrapped the component in this Luxon LocalizationProvider for use on the DoB field. But
                  ideally, this provider should wrap the entire App, once we convert all of the legacy
                  datetime logic to Luxon. See ticket: https://montugroup.atlassian.net/browse/B2C-5025
                */
                return (
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    {/*
                      NOTE: I've not used the 'DatePickerElement' from the 'react-hook-form-mui' package because it does not
                      allow for custom date formats, and it's only available format is MM/DD/YYY, which is not good for Australian users
                    */}
                    <StyledDatePicker
                      label="DoB"
                      value={value}
                      onChange={(newValue) => onChange(newValue)}
                      format="dd/MM/yyyy"
                      disabled={!isEditMode}
                    />
                  </LocalizationProvider>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="dvaNumber" label="DVA Number" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldElement name="gender" label="Gender" disabled={!isEditMode} placeholder="-" fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectElement
              name="atsi_status"
              label="Aboriginal / Torres Strait Islander"
              options={listOfAtsiOptionsForSelect}
              disabled={!isEditMode}
              fullWidth
              data-testid={'atsi-status'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {(isAdmin || isNurse || isManager) && (
              /* 'disabled' prop does not exist on <AutocompleteElement> so we prevent interaction with 'pointerEvents=none' when form is disabled */
              <Box sx={{ pointerEvents: isEditMode ? 'auto' : 'none', opacity: isEditMode ? 1 : 0.7 }}>
                <AutocompleteElement name="pmsDoctor" label="Doctor" options={listOfDoctorsForSelect ?? []} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Typography color={grey[500]}>Consent</Typography>
              <Checkbox checked={Boolean(defaultValues.consent)} name="consent" disabled={true} />
              {showConsentFormResendLink && (
                <StyledResendConsentButton onClick={handleResendConsentForm} color={'secondary'}>
                  Resend Consent Form
                </StyledResendConsentButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </FormContainer>
    </EditableSections>
  );
};

export default withEditModeContextProvider(PatientInformation);
